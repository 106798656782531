//检查手机号
const checkMobile = (mobile) => {
	let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
	if (mobile.length != 11 || !myreg.test(mobile)) {
		return false;
	} else {
		return true;
	}
}

/**
 * 检查一个值是否在数组里
 */
const inArray = (item, arr) => {
	
	for(let i=0; i<arr.length; i++){
		
		if(item == arr[i]){
			
			return true;
		}
		
	}
	
	return false;
	
}



export default {
	checkMobile,
	inArray,
}
