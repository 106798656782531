import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'
import router from './router'

import cache from './utils/cache.js';
import http from './utils/http.js';
import tool from './utils/tool.js';

import config from './config/config.js';

Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$cache = cache;
Vue.prototype.$http = http;
Vue.prototype.$tool = tool;
Vue.prototype.$config = config;

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
