<template>
	<div>
		<div class="operation">
			<el-form :inline="true" :model="selectForm" class="demo-form-inline">
				<el-form-item label="" label-width="0">
					<el-input v-model="selectForm.name" placeholder="分类名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="selectForm.page = 1;getList();">
						查询
					</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="dialogVisible = true" style="margin-right: 20px;">
						<i class="el-icon-circle-plus-outline"></i>
						<span>添加分类</span>
					</el-button>
				</el-form-item>
			</el-form>
		</div>

		<div class="dataList">
			<el-table :data="dataList" border style="width: 100%;" height="100%">
				<el-table-column prop="id" label="ID" width="50"></el-table-column>
				<el-table-column prop="name" label="分类名称"></el-table-column>
				<el-table-column prop="sort" label="排序"></el-table-column>


				<el-table-column label="图片">
					<template slot-scope="scope">
						<img :src="scope.row.image" height="25" />
					</template>
				</el-table-column>
				
				<el-table-column label="是否显示" width="80">
					<template slot-scope="scope">
						<el-tag type="success" v-if="scope.row.is_display == '1'">是</el-tag>
						<el-tag type="danger" v-else>否</el-tag>
					</template>
				</el-table-column>


				<el-table-column label="操作" width="300">
					<template slot-scope="scope">
						<el-button type="primary" @click="handleEdit(scope.row)">编辑</el-button>
						<el-button type="success" @click="bindGoods(scope.row)">绑定商品</el-button>

						<el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div>

		<el-dialog title="添加/编辑" :visible.sync="dialogVisible" center>
			<el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="right">
				<el-form-item label="分类名称:" prop="name">
					<el-input v-model="dataForm.name"></el-input>
				</el-form-item>

				<el-form-item label="排序:">
					<el-input v-model="dataForm.sort"></el-input>
				</el-form-item>
				
				<el-form-item label="是否显示:" prop="is_display">
					<el-switch v-model="dataForm.is_display" active-value="1" inactive-value="0"></el-switch>
				</el-form-item>

				<el-form-item label="图片">
					<el-upload class="avatar-uploader" :action="uploadServer" :headers="headers" :show-file-list="false"
						:on-success="handleUploadSuccess" :on-error="handleUploadError"
						:before-upload="beforeAvatarUpload">
						<img v-if="dataForm.image" :src="dataForm.image" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>


			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="submitForm()">保存</el-button>
				<el-button @click="resetForm()">重置</el-button>
			</div>
		</el-dialog>



		<el-dialog title="绑定商品" :visible.sync="dialogVisible2" center>


			<el-input v-model="goodsNameSearch" @input="searchGoodsName" style="width: 300px;" placeholder="商品名称搜索"></el-input>


			<el-table ref="multipleTable" :data="tableList" tooltip-effect="dark" style="width: 100%;" max-height="400"
				@selection-change="handleSelectionChange">



				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column label="ID" width="60">
					<template slot-scope="scope">{{ scope.row.id }}</template>
				</el-table-column>
				<el-table-column prop="name" label="名称" width="200">
				</el-table-column>
				<el-table-column prop="description" label="描述" show-overflow-tooltip>
				</el-table-column>
			</el-table>


			<div slot="footer">
				<el-button type="primary" @click="bindGoodsSubmitForm()">保存</el-button>
				<el-button @click="dialogVisible2=false">关闭</el-button>
			</div>
		</el-dialog>


	</div>
</template>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	export default {
		data() {
			return {
				goodsNameSearch: "",
				total: 0,
				selectForm: {
					size: 20,
					page: 1,
					name: '',
				},

				dialogVisible: false,
				dialogVisible2: false,

				//列表数据
				dataList: [
					// {
					// 	id: 1,
					// 	name: '商品1',
					// },
				],


				//表单数据
				dataForm: {
					id: 0, //编辑时才有
					name: '',
					sort: 0,
					image: "",
					is_display:"1",
				},
				//表单验证规则
				rules: {
					name: [{
						required: true,
						message: '请输入分类名称',
						trigger: 'blur'
					}]

				},

				dialogImageUrl: '',
				imageDialogVisible: false,


				//上传接口
				uploadServer: '',
				headers: {
					Authorization: ""
				},


				tableData: [
					// {
					// 	id: 1,
					// 	name: '商品',
					// 	description: '描述'
					// },
					// {
					// 	id: 2,
					// 	name: '商品2',
					// 	description: '描述222'
					// },
				],

				tableList: [],

				multipleSelection: [],
				currentCategoryId: 0
			};
		},

		created() {

			this.uploadServer = this.$config.upload_api68;
			this.headers.Authorization = this.$cache.get('token');

			this.getList();
		},

		computed: {

		},

		methods: {

			searchGoodsName() {

				this.tableList = [];

				this.$refs.multipleTable.clearSelection();


				for (let i = 0; i < this.tableData.length; i++) {
					
					

					if(this.goodsNameSearch =='' || this.tableData[i].name.indexOf(this.goodsNameSearch) != -1 ){
								this.tableList.push(this.tableData[i]);
					}
			

				}
				
				
				this.$nextTick(() => {
				
					for (let i = 0; i < this.tableList.length; i++) {
						if (this.tableList[i].is_select == 1) {
							this.$refs.multipleTable.toggleRowSelection(this.tableList[i]);
						}
					}
				
				})

			},

			/**
			 * 获取列表
			 */
			getList() {
				this.$http
					.post('/manage/shopCategory/list', this.selectForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.dataList = result.list;
							this.total = result.total;

						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			/**
			 * 表单提交
			 */
			submitForm() {
				this.$refs['ruleForm'].validate(valid => {
					if (valid) {
						console.log(this.dataForm);

						this.$http
							.post('/manage/shopCategory/save', this.dataForm)
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								console.log(errcode);

								if (errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									});
									this.resetForm();
									this.dialogVisible = false;
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			/**
			 * 表单重置
			 */
			resetForm() {
				this.dataForm = {
					id: 0, //编辑时才有
					name: '',
					sort: 0,
					image: "",
					is_display:"1"
				};
				this.$refs['ruleForm'].resetFields();
			},

			/**
			 * 删除
			 * @param {Object} row
			 */
			handleDelete(row) {
				console.log(row);

				this.$confirm('删除【' + row.name + '】, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.post('/manage/shopCategory/del', {
								id: row.id
							})
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								if (errcode == 0) {
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					})
					.catch(() => {});
			},

			/**
			 * 查看详情
			 * @param {Object} e
			 */
			handleEdit(row) {
				console.log(row);
				this.dataForm = JSON.parse(JSON.stringify(row));
				this.dialogVisible = true;
			},



			/**
			 * 分页改变
			 * @param {Object} e
			 */
			handleCurrentChange(e) {
				this.selectForm.page = e;
				this.getList();
			},



			/**
			 * 上传成功
			 * @param {Object} response
			 * @param {Object} file
			 * @param {Object} fileList
			 */
			handleUploadSuccess(response, file) {
				console.log(file)
				// this.imageUrl = URL.createObjectURL(file.raw);
				this.dataForm.image = response.result.url;
			},

			/**
			 * 上传失败
			 * @param {Object} response
			 * @param {Object} file
			 * @param {Object} fileList
			 */
			handleUploadError(err, file, fileList) {
				console.log(err, file, fileList);
			},

			//上传之前
			beforeAvatarUpload(file) {

				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isLt2M;
			},


			bindGoods(row) {
				console.log(row);
				this.currentCategoryId = row.id;
				this.dialogVisible2 = true;

				this.$http
					.post('/manage/shopCategory/getAllGoods', {
						id: row.id
					})
					.then(res => {
						let {
							errcode,
							msg,
							result
						} = res;

						if (errcode == 0) {
							this.tableData = result;
							this.tableList = result;
							this.$nextTick(() => {

								for (let i = 0; i < this.tableList.length; i++) {
									if (this.tableList[i].is_select == 1) {
										this.$refs.multipleTable.toggleRowSelection(this.tableList[i]);
									}
								}

							})

						} else {
							this.$message.error(msg);
						}
					})
					.catch(() => {
						this.$message.error('保存失败');
					});


			},


			handleSelectionChange(val) {
				this.multipleSelection = val;
				// console.log(this.multipleSelection)
			},


			bindGoodsSubmitForm() {
				console.log(this.multipleSelection)

				this.$http
					.post('/manage/shopCategory/bindGoods', {
						id: this.currentCategoryId,
						data: this.multipleSelection
					})
					.then(res => {
						let {
							errcode,
							msg,
							// result
						} = res;

						if (errcode == 0) {
							this.$message.success('绑定成功');
							this.dialogVisible2 = false

						} else {
							this.$message.error(msg);
						}
					})
					.catch(() => {
						this.$message.error('保存失败');
					});
			}


		}
	};
</script>
