<template>
	<div>
		<div class="operation">
			<el-form :inline="true" :model="selectForm" class="demo-form-inline">
				<el-form-item label="" label-width="0">
					<el-input v-model="selectForm.name" placeholder="商品名称"></el-input>
				</el-form-item>
				<el-form-item label="" label-width="0">
					<el-select v-model="selectForm.is_sale" placeholder="是否商城销售">
						<el-option key="1000" label="全部" value="1000"></el-option>
						<el-option key="0" label="否" value="0"></el-option>
						<el-option key="1" label="是" value="1"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="selectForm.page = 1;getList();">
						查询
					</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="dialogVisible = true" style="margin-right: 20px;">
						<i class="el-icon-circle-plus-outline"></i>
						<span>添加商品</span>
					</el-button>
				</el-form-item>
			</el-form>
		</div>

		<div class="dataList">
			<el-table :data="dataList" border style="width: 100%;" height="100%">
				<el-table-column prop="id" label="ID" width="50"></el-table-column>
				<el-table-column prop="name" label="商品名称"></el-table-column>
				<el-table-column prop="category_name" label="商品分类"></el-table-column>
				<el-table-column label="商城封面">
					<template slot-scope="scope">
						<img :src="scope.row.logo" height="25" />
					</template>
				</el-table-column>
				<el-table-column label="规格 价格 成本" width="300">
					<template slot-scope="scope">
						<el-table :data="scope.row.specs" border style="width: 100%" :show-header="false">
							<el-table-column label="规格">
								<template slot-scope="scope2">
									{{ scope2.row.std1 }} {{ scope2.row.std2 }}
								</template>
							</el-table-column>
							<el-table-column prop="amount" label="价格"></el-table-column>
							<el-table-column prop="cost" label="陈本"></el-table-column>
						</el-table>
					</template>
				</el-table-column>

				<el-table-column label="商城销售" width="80">
					<template slot-scope="scope">
						<el-tag type="success" v-if="scope.row.is_sale">是</el-tag>
						<el-tag type="danger" v-else>否</el-tag>
					</template>
				</el-table-column>
				
				<el-table-column label="只对安全客户销售" width="150">
					<template slot-scope="scope">
						<el-tag type="success" v-if="scope.row.is_safe_member_sale">是</el-tag>
						<el-tag type="danger" v-else>否</el-tag>
					</template>
				</el-table-column>

				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button type="primary" @click="handleEdit(scope.row)">编辑</el-button>
						<el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div>

		<el-dialog title="添加/编辑" :visible.sync="dialogVisible" center>
			<el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="130px" label-position="right">
				<el-form-item label="商品名称:" prop="name">
					<el-input v-model="dataForm.name"></el-input>
				</el-form-item>

				<el-form-item label="商城售卖:" prop="is_sale">
					<el-switch v-model="dataForm.is_sale"></el-switch>
				</el-form-item>
				
				<el-form-item label="只对安全客户销售:" prop="is_safe_member_sale">
					<el-switch v-model="dataForm.is_safe_member_sale"></el-switch>
				</el-form-item>

				<el-form-item label="商品分类:" prop="category_ids">
					<el-checkbox-group v-model="dataForm.category_ids">
						<el-checkbox v-for="cat in categoryList" :label="cat.id" :key="cat.id">{{ cat.name }}
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>

				<el-form-item label="图册(最多5张):">
					<el-upload ref="upload" :file-list="dataForm.imageList" :action="uploadServer" :headers="headers"
						list-type="picture-card" :limit="5" :auto-upload="true" :on-success="handleUploadSuccess"
						:on-error="handleUploadError">
						<i slot="default" class="el-icon-plus"></i>
						<div slot="file" slot-scope="{ file }">
							<img class="el-upload-list__item-thumbnail" :src="file.thumb" alt="" />
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)"><i
										class="el-icon-zoom-in"></i></span>
								<span class="" @click="handleRemove(file)"><i class="el-icon-delete"></i></span>
							</span>
						</div>
					</el-upload>
				</el-form-item>
				
				<el-form-item label="商城封面(200*200)">
					<el-upload class="avatar-uploader" :action="uploadServer2" :headers="headers" :show-file-list="false"
						:on-success="handleUploadSuccess2" :on-error="handleUploadError2"
						:before-upload="beforeAvatarUpload2">
						<img v-if="dataForm.logo" :src="dataForm.logo" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>

				<el-form-item label="商品描述" prop="description">
					<el-input type="textarea" v-model="dataForm.description"></el-input>
				</el-form-item>

				<div style="display: flex;" v-for="(item, index) in dataForm.specs" :key="item.des + index">
					<el-form-item :label="item.des + (index + 1)" v-if="index == 0" prop="specs[0].std1">
						<el-input placeholder="主规格" v-model="item.std1" maxlength="10"></el-input>
					</el-form-item>
					<el-form-item :label="item.des + (index + 1)" v-else>
						<el-input placeholder="主规格" v-model="item.std1" maxlength="10"></el-input>
					</el-form-item>

					<el-form-item label-width="0">
						<el-input placeholder="次规格(可无)" v-model="item.std2" maxlength="10"></el-input>
					</el-form-item>

					<el-form-item label-width="0" v-if="index == 0" prop="specs[0].amount">
						<el-input placeholder="价格(元)" v-model="item.amount" maxlength="10"></el-input>
					</el-form-item>
					<el-form-item label-width="0" v-else>
						<el-input placeholder="价格(元)" v-model="item.amount" maxlength="10"></el-input>
					</el-form-item>
					<el-form-item label-width="0">
						<el-input placeholder="成本(元)" v-model="item.cost" maxlength="10"></el-input>
					</el-form-item>
					<el-form-item label-width="0" style="padding: 0 10px;">
						<i class="el-icon-menu" style="font-size: 24px; color: #1E90FF;"
							@click="moreSpecsInfo(item)"></i>
					</el-form-item>
					<el-form-item label-width="0">
						<i v-if="index > 0" class="el-icon-remove" style="font-size: 24px; color: #ff0000;"
							@click="removeSpecs(index)"></i>
					</el-form-item>
				</div>

				<el-form-item>
					<el-button type="primary" size="mini" @click="addSpecs">添加规格</el-button>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="submitForm()">保存</el-button>
				<el-button @click="resetForm()">重置</el-button>
			</div>

			<el-dialog width="30%" title="规格搭配/备注/打印内容" :visible.sync="innerVisible" append-to-body>

				<el-form :model="dataFormSpecsInfo" label-width="60px" label-position="right">
					<el-form-item label="搭配">
						<el-input rows="4" type="textarea" v-model="dataFormSpecsInfo.collocation"></el-input>
					</el-form-item>
					<el-form-item label="备注">
						<el-input type="textarea" v-model="dataFormSpecsInfo.remark"></el-input>
					</el-form-item>
					<el-form-item label="打印">
						<el-input rows="5" type="textarea" v-model="dataFormSpecsInfo.print_content" placeholder="打印内容,商品名称无需写"></el-input>
					</el-form-item>
				</el-form>
				
			</el-dialog>

		</el-dialog>

		<el-dialog :visible.sync="imageDialogVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
	</div>
</template>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	export default {
		data() {
			return {
				total: 0,
				selectForm: {
					size: 20,
					page: 1,
					name: '',
					is_sale: ''
				},

				dialogVisible: false,

				innerVisible: false,

				//上传接口
				uploadServer: '',
				uploadServer2: '',
				headers: {
					Authorization: ""
				},

				//列表数据
				dataList: [
					// {
					// 	id: 1,
					// 	name: '商品1',
					// 	category_name: '分类1',
					// 	specs: [{
					// 			std1: 'XL',
					// 			std2: '红色',
					// 			amount: '10.00'
					// 		},
					// 		{
					// 			std1: 'XL',
					// 			std2: '黑色',
					// 			amount: '10.00'
					// 		}
					// 	]
					// },
				],

				//分类列表
				categoryList: [
					// {
					// 	id: 1,
					// 	name: '分类1'
					// },
				],

				//表单数据
				dataForm: {
					id: 0, //编辑时才有
					name: '',
					description: '',
					is_sale: false,
					is_safe_member_sale: true,
					category_ids: [],
					specs: [{
						des: '规格',
						std1: '',
						std2: '',
						amount: '',
						cost: '',
						collocation: '',
						remark: '',
						print_content:''
					}],
					imageList: [],
					logo:""
				},

				//规格搭配和备注
				dataFormSpecsInfo: {
					collocation: "",
					remark: "",
					print_content:""
				},


				//表单验证规则
				rules: {
					name: [{
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					}],
					category_ids: [{
						type: 'array',
						required: true,
						message: '请至少选择一个分类',
						trigger: 'change'
					}],

					'specs[0].std1': [{
						required: true,
						message: '规格1 主规格必须',
						trigger: 'blur'
					}],

					'specs[0].amount': [{
						required: true,
						message: '规格1 价格必须',
						trigger: 'blur'
					}]
				},

				dialogImageUrl: '',
				imageDialogVisible: false
			};
		},

		created() {
			this.ini();
			this.getList();
		},

		methods: {
			/**
			 * 获取列表
			 */
			getList() {
				this.$http
					.post('/manage/goods/list', this.selectForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.dataList = result.list;
							this.total = result.total;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},

			//页面初始化加载数据
			ini() {

				this.uploadServer = this.$config.upload_api;
				this.uploadServer2 = this.$config.upload_api200;
				this.headers.Authorization = this.$cache.get('token');

				this.$http
					.post('/manage/category/all')
					.then(res => {
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.categoryList = result;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},

			/**
			 * 表单提交
			 */
			submitForm() {
				this.$refs['ruleForm'].validate(valid => {
					if (valid) {
						console.log(this.dataForm);

						this.$http
							.post('/manage/goods/save', this.dataForm)
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								console.log(errcode);

								if (errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									});
									this.resetForm();
									this.dialogVisible = false;
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			/**
			 * 表单重置
			 */
			resetForm() {
				this.dataForm = {
					id: 0, //编辑时才有
					name: '',
					description: '',
					is_sale: false,
					is_safe_member_sale: true,
					category_ids: [],
					specs: [{
						des: '规格',
						std1: '',
						std2: '',
						amount: '',
						collocation: '',
						remark: '',
						print_content:''
					}],
					imageList: []
				};
				this.$refs['ruleForm'].resetFields();
			},

			/**
			 * 删除
			 * @param {Object} row
			 */
			handleDelete(row) {
				console.log(row);

				this.$confirm('删除【' + row.name + '】, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.post('/manage/goods/del', {
								id: row.id
							})
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								if (errcode == 0) {
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					})
					.catch(() => {});
			},

			/**
			 * 查看详情
			 * @param {Object} e
			 */
			handleEdit(row) {
				console.log(row);

				this.dataForm = JSON.parse(JSON.stringify(row));
				this.dialogVisible = true;
			},

			/**
			 * 上传成功
			 * @param {Object} response
			 * @param {Object} file
			 * @param {Object} fileList
			 */
			handleUploadSuccess(response) {
				this.dataForm.imageList.push({
					thumb: response.result.thumb,
					url: response.result.url
				});
			},

			/**
			 * 上传失败
			 * @param {Object} response
			 * @param {Object} file
			 * @param {Object} fileList
			 */
			handleUploadError(err, file, fileList) {
				console.log(err, file, fileList);
			},

			/**
			 * 删除
			 * @param {Object} file
			 */
			handleRemove(file) {
				console.log(file);

				console.log(this.dataForm.imageList);

				let index = this.dataForm.imageList.findIndex(fileItem => {
					return fileItem.url === file.url;
				});
				console.log(index);
				this.dataForm.imageList.splice(index, 1);
			},
			/**
			 * 查看图片
			 * @param {Object} file
			 */
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.imageDialogVisible = true;
			},

			/**
			 * 分页改变
			 * @param {Object} e
			 */
			handleCurrentChange(e) {
				this.selectForm.page = e;
				this.getList();
			},

			addSpecs() {
				this.dataForm.specs.push({
					des: '规格',
					std1: '',
					std2: '',
					amount: '',
					collocation: '',
					remark: '',
					print_content:''
				});
			},

			moreSpecsInfo(row) {
				console.log(row);

				this.dataFormSpecsInfo = row;
				this.innerVisible = true;				
			},

			removeSpecs(index) {
				console.log(index);
				this.dataForm.specs.splice(index, 1);
			},
			
			
			/**
			 * 上传成功
			 * @param {Object} response
			 * @param {Object} file
			 * @param {Object} fileList
			 */
			handleUploadSuccess2(response, file) {
				console.log(file)
				// this.imageUrl = URL.createObjectURL(file.raw);
				this.dataForm.logo = response.result.url;
			},
			
			/**
			 * 上传失败
			 * @param {Object} response
			 * @param {Object} file
			 * @param {Object} fileList
			 */
			handleUploadError2(err, file, fileList) {
				console.log(err, file, fileList);
			},
			
			//上传之前
			beforeAvatarUpload2(file) {
			
				const isLt2M = file.size / 1024 / 1024 < 2;
			
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isLt2M;
			},
		}
	};
</script>
