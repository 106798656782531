<template>
	<div>
		<div class="operation">
			<el-form :inline="true" :model="selectForm" class="demo-form-inline">
				<el-form-item label="" label-width="0">
					<el-select v-model="selectForm.date_range" placeholder="周期">

						<el-option key="1" label="当前月" value="1"></el-option>
						<el-option key="3" label="近三月" value="3"></el-option>
						<el-option key="6" label="近半年" value="6"></el-option>

					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="selectForm.page = 1;getList();">
						查询
					</el-button>
				</el-form-item>
				
			</el-form>
		</div>

		<div class="dataList">
			<el-table :data="dataList" border style="width: 100%;" height="100%">
		
				<el-table-column prop="month" label="月份"></el-table-column>
				<el-table-column prop="order_num" label="订单数(已付款)"></el-table-column>
				<el-table-column prop="amount" label="总客单价"></el-table-column>
				<el-table-column prop="goods_amount" label="订单商品总额"></el-table-column>
				<el-table-column prop="cost_price" label="进货成本"></el-table-column>
				<el-table-column prop="profit" label="本月毛利"></el-table-column>




			</el-table>
		</div>

<!-- 		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div> -->


	</div>
</template>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	export default {
		data() {
			return {
				total: 0,
				selectForm: {
					size: 20,
					page: 1,
					date_range: '1',
				},

			
				//列表数据
				dataList: [
					// {
					// 	id: 1,
					// 	name: '商品1',
					// },
				],


				//表单数据
				dataForm: {
					id: 0, //编辑时才有
					types:1,
					name: '',
				},
				//表单验证规则
				rules: {
			

				},

				dialogImageUrl: '',
				imageDialogVisible: false
			};
		},

		created() {
			this.getList();
		},

		methods: {
			/**
			 * 获取列表
			 */
			getList() {
				this.$http
					.post('/manage/report/orderReport', this.selectForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.dataList = result;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			/**
			 * 表单重置
			 */
			resetForm() {
				this.dataForm = {
					id: 0, //编辑时才有
					name: '',
				};
				this.$refs['ruleForm'].resetFields();
			},


			/**
			 * 查看详情
			 * @param {Object} e
			 */
			handleEdit(row) {
				console.log(row);
				this.dataForm = JSON.parse(JSON.stringify(row));
				this.dialogVisible = true;
			},



			/**
			 * 分页改变
			 * @param {Object} e
			 */
			handleCurrentChange(e) {
				this.selectForm.page = e;
				this.getList();
			},


		}
	};
</script>
