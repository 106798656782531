<template>
	<div>

		<div class="operation">

			<el-form :inline="true" :model="selectForm" class="demo-form-inline">
			
				<el-form-item>
					<el-button type="primary" @click="addUserIntegral" style="margin-right: 20px;">
						<i class="el-icon-circle-plus-outline"></i>
						<span>添加积分</span>
					</el-button>
				</el-form-item>
			</el-form>

		</div>


		<div class="dataList">
			<el-table :data="dataList" border style="width: 100%;" height="100%">
				<el-table-column prop="id" label="ID" width="100"></el-table-column>

				<el-table-column prop="nickname" label="用户"></el-table-column>
				<el-table-column prop="integral" label="积分"></el-table-column>
				<el-table-column prop="des" label="来源"></el-table-column>



				<el-table-column prop="create_time" label="时间"></el-table-column>
	
			</el-table>
		</div>

		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div>

		<el-dialog title="添加积分" :visible.sync="dialogVisible" center>
			<el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="right">

				<el-form-item label="积分:" prop="number">
					<el-input v-model="dataForm.number"></el-input>
				</el-form-item>
				
				<el-form-item label="来源:"  prop="des">
					<el-input v-model="dataForm.des"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="submitForm()">确认</el-button>
				<!-- <el-button @click="resetForm()">重置</el-button> -->
			</div>
		</el-dialog>






	</div>
</template>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	export default {
		data() {
			return {

				user_id: 0,

				total: 0,
				selectForm: {
					size: 20,
					page: 1,
					user_id: 0,
				},

				dialogVisible: false,


				//列表数据
				dataList: [

				],


				//表单数据
				dataForm: {
					userId: 0, 
					number: 1,
					des:"客服赠送"

				},
				//表单验证规则
				rules: {
					number: [{
						required: true,
						message: '请输入数量',
						trigger: 'blur'
					}],
					des: [{
						required: true,
						message: '请输入来源',
						trigger: 'blur'
					}]

				},


				allCoupon: [],


			};
		},

		created() {
			
	
			
			if(this.$route.query.user_id){
				this.user_id = this.$route.query.user_id;
				this.dataForm.userId = this.$route.query.user_id;
				this.selectForm.user_id = this.$route.query.user_id;
				
				this.getList();
			}


			
		},

		methods: {
			/**
			 * 获取列表
			 */
			getList() {
				this.$http
					.post('/manage/integral/getUserIntegralList', this.selectForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.dataList = result.list;
							this.total = result.total;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			/**
			 * 表单提交
			 */
			submitForm() {
				this.$refs['ruleForm'].validate(valid => {
					if (valid) {
						console.log(this.dataForm);

						this.$http
							.post('/manage/integral/saveUserIntegralList', this.dataForm)
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								console.log(errcode);

								if (errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									});
									
									this.dialogVisible = false;
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		

			/**
			 * 查看详情
			 * @param {Object} e
			 */
			handleEdit(row) {
				console.log(row);
				this.dataForm = JSON.parse(JSON.stringify(row));
				this.dialogVisible = true;
			},



			/**
			 * 分页改变
			 * @param {Object} e
			 */
			handleCurrentChange(e) {
				this.selectForm.page = e;
				this.getList();
			},

			/**
			 * 
			 */
			addUserIntegral() {
				this.dialogVisible = true

			}

		}
	};
</script>
