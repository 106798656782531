<template>
	<div id="app">
		<layout v-if="isLogin" />
		<login v-else />
	</div>
</template>

<script>
import layout from './components/layout.vue';
import login from './components/login.vue';

export default {
	name: 'App',
	components: {
		layout,
		login
	},
	data() {
		return {
			
		};
	},
	created() {
		
	},
	computed:{
		isLogin(){
			return this.$cache.get('token');
		}
	}
};
</script>

<style>
* {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

img {
	vertical-align: top;
	border: none;
}

#app {
	margin: 0;
	padding: 0;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	color: #2c3e50;
	/* margin-top: 60px; */
}

.el-main {
	padding: 10px;
}
</style>
