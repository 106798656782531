import Vue from "vue";
import VueRouter from "vue-router";
// import cache from "../utils/cache.js"

Vue.use(VueRouter);

// 引入组件
import goodsList from "../views/goods/list.vue";
import categoryList from "../views/category/list.vue";
import orderList from "../views/order/list.vue";
import orderCreate from "../views/order/create.vue";
import orderGoodsList from "../views/order/goodsList.vue";
import orderDabao from "../views/order/dabao.vue";

import shopCategoryList from "../views/shopCategory/list.vue";
import userList from "../views/user/list.vue";
import userCouponList from "../views/user/couponList.vue";
import userIntegralList from "../views/user/integralList.vue";
import userTestList from "../views/user/testList.vue";
import couponList from "../views/coupon/couponList.vue";
import bannerList from "../views/banner/list.vue";
import noticesList from "../views/notices/list.vue";

import testConfigList from "../views/testConfig/list.vue";

import configEdit from "../views/config/edit.vue";

import activityCouponList from "../views/activityCoupon/list.vue";

import exchangeList from "../views/exchange/list.vue";

import skuList from "../views/sku/list.vue";
import pricecount from "../views/sku/pricecount.vue";
import goodslist from "../views/sku/goodslist.vue";

import reportList from "../views/report/list.vue";
import costList from "../views/cost/list.vue";

const routes = [
	{
		name: '商品列表',
		path: "/goods/list",
		component: goodsList
	},
	
	{
		name: '商品分类',
		path: "/category/list",
		component: categoryList
	},
	
	{
		name: '订单列表',
		path: "/order/list",
		component: orderList
	},
	{
		name: '客服下单',
		path: "/order/create",
		component: orderCreate
	},
	{
		name: '订单商品',
		path: "/order/goodsList",
		component: orderGoodsList
	},
	
	{
		name: '商城分类',
		path: "/shopCategory/list",
		component: shopCategoryList
	},
	{
		name: '用户管理',
		path: "/user/list",
		component: userList
	},
	{
		name: '用户优惠券',
		path: "/user/couponList",
		component: userCouponList
	},
	{
		name: '用户积分',
		path: "/user/integralList",
		component: userIntegralList
	},
	{
		name: '用户测试',
		path: "/user/testList",
		component: userTestList,
	},
	{
		name: '优惠券列表',
		path: "/coupon/couponList",
		component: couponList
	},
	{
		name: 'BANNER列表',
		path: "/banner/list",
		component: bannerList
	},
	{
		name: '公告列表',
		path: "/notices/list",
		component: noticesList
	},
	{
		name: '体质配置',
		path: "/testConfig/list",
		component: testConfigList
	},
	{
		name: '系统配置',
		path: "/config/edit",
		component: configEdit
	},
	{
		name: '活动券',
		path: "/activityCoupon/list",
		component: activityCouponList
	},
	{
		name: '用户兑换',
		path: "/exchange/list",
		component: exchangeList
	},
	{
		name: '物料列表',
		path: "/sku/list",
		component: skuList
	},
	{
		name: '物料成本计算',
		path: "/sku/pricecount",
		component: pricecount
	},
	{
		name: '商品绑定',
		path: "/sku/goodslist",
		component: goodslist
	},
	{
		name: '订单打包',
		path: "/order/dabao",
		component: orderDabao
	},
	{
		name: '经营报表',
		path: "/report/list",
		component: reportList
	},
	{
		name: '进货记录',
		path: "/cost/list",
		component: costList
	},
]


const router = new VueRouter({
	// mode: 'history',
	routes
})


// router.beforeEach((to, from, next) => {
	

	
// 	if (!token) {

// 	} else {
// 		next()
// 	}

// })



export default router;
