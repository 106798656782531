const set = (key, value, timeout = 2592000000) => {
	let valueObj = {
		"value": value,
		"expire": Date.now() + timeout
	}
	if (timeout == -1) {
		valueObj.expire = -1
	}
	localStorage.setItem(key, JSON.stringify(valueObj));
	return value;
}

const get = (key) => {

	let valueJson = localStorage.getItem(key);

	if (!valueJson)
		return "";

	let valueObj = JSON.parse(valueJson);

	if (!valueObj)
		return "";

	if (valueObj.expire > 0) {
		if (valueObj.expire < Date.now()) {
			del(key);
			return "";
		}
	}
	if (valueObj.expire == -1) {
		return valueObj.value;
	}
	return valueObj.value;
}


const del = (key) => {
	localStorage.removeItem(key);
}


const clear = () => {
	localStorage.clear();
}

export default {
	set,
	get,
	del,
	clear
}
