<template>
	<div style="">
		<div style="padding-bottom: 10px;">订单：{{order.order_no}}，收货人：{{order.to_full_name}}</div>
		
		<el-table :data="goodsList" stripe style="width: 100%; color: #999999;" border>
			<el-table-column prop="goods_name" label="产品" width="200">
			</el-table-column>
			<el-table-column prop="num" label="数量" width="60">
			</el-table-column>
			<el-table-column prop="info" label="材料">
			</el-table-column>
		</el-table>
		
		<div style="height: 20px;"></div>
		
		<el-collapse accordion>
			<el-collapse-item v-for="(item,i) in datas" :key="i">
				<template slot="title">
					<span style="font-size: 22px;">{{item.name}} 【{{item.num}}】 {{item.unit}}</span>
				</template>
				<el-table :data="item.goodsList" stripe style="width: 100%" border>

					<el-table-column prop="name" label="产品" width="300">
					</el-table-column>
					<el-table-column prop="num" label="数量" width="60">
					</el-table-column>
					<el-table-column prop="info" label="材料">
					</el-table-column>

					<el-table-column fixed="right" label="操作" width="100">
					<!-- 	<template slot-scope="scope">
							<el-button @click="handleClick(scope.row)" type="primary">完成</el-button>

						</template> -->
					</el-table-column>
				</el-table>
			</el-collapse-item>

		</el-collapse>


	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				goodsNameSearch: "",

				activeNames: ['1'],
				
				order:{},
				
				goodsList:[],

				datas: [
					// {
					// 	name: "红枣",
					// 	num:"12",
					// 	unit:"个",
					// 	goodsList: [{
					// 			id:1,
					// 			name: '健脾补气养胃汤（自配）1【常规】',
					// 			num: '2',
					// 			info: '养肝草 2 个，大椰王 20 克，白莲子 15 克，无花果 6 个，麦冬 20 克，枸杞 10 克，蜜枣 30 克'
					// 		},
					// 		{
					// 			id:2,
					// 			name: '健脾补气养胃汤（自配）【常规】222',
					// 			num: '2',
					// 			info: '养肝草 2 个，大椰王 20 克，白莲子 15 克，无花果 6 个，麦冬 20 克，枸杞 10 克，蜜枣 30 克'
					// 		}, {
					// 			id:3,
					// 			name: '健脾补气养胃汤（自配）【常规】222',
					// 			num: '2',
					// 			info: '养肝草 2 个，大椰王 20 克，白莲子 15 克，无花果 6 个，麦冬 20 克，枸杞 10 克，蜜枣 30 克'
					// 		},

					// 	]
					// }
				
				]


			};
		},

		created() {
			this.orderId = this.$route.query.id
			this.loadGoods();
		},

		methods: {

			handleChange(val) {
				console.log(val);
			},

			loadGoods() {
				this.$http
					.post('/manage/order/dabaoGoods',{id:this.orderId})
					.then(res => {
						console.log(res);
						let {
							errcode,
							result,
							msg
						} = res;
						if (errcode === 0) {
							// console.log(result)
							// this.goodsList = result;
							this.datas = result.datas;
							this.order = result.order;
							this.goodsList = result.goodsList;
						}else{
							this.$message({
								message: msg,
								type: 'error'
							});
						}
					})
					.catch(error => {
						console.log(error)
						
					});
			},

			handleClick() {

			}


		}
	};
</script>

<style>
</style>
