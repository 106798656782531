<template>
	<div>



		<div class="dataList">
			<el-table :data="dataList" border style="width: 100%;" height="100%">
				<el-table-column prop="id" label="ID" width="100"></el-table-column>

				<el-table-column prop="user_id" label="用户ID" width="100"></el-table-column>
				<el-table-column prop="des" label="结果"></el-table-column>
				<el-table-column prop="result" label="参考分"></el-table-column>

				<el-table-column prop="create_time" label="时间" width="170"></el-table-column>
	
			</el-table>
		</div>

		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div>






	</div>
</template>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	export default {
		data() {
			return {

				user_id: 0,

				total: 0,
				selectForm: {
					size: 20,
					page: 1,
				},

				dialogVisible: false,


				//列表数据
				dataList: [

				],




			};
		},

		created() {
			
	this.getList();
			
			// if(this.$route.query.user_id){
			// 	this.user_id = this.$route.query.user_id;
			// 	this.dataForm.userId = this.$route.query.user_id;
				
			// 	this.getList();
			// }


			
		},

		methods: {
			/**
			 * 获取列表
			 */
			getList() {
				this.$http
					.post('/manage/userTest/getTestList', this.selectForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.dataList = result.list;
							this.total = result.total;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},



			/**
			 * 查看详情
			 * @param {Object} e
			 */
			handleEdit(row) {
				console.log(row);
				this.dataForm = JSON.parse(JSON.stringify(row));
				this.dialogVisible = true;
			},



			/**
			 * 分页改变
			 * @param {Object} e
			 */
			handleCurrentChange(e) {
				this.selectForm.page = e;
				this.getList();
			},

			/**
			 * 
			 */
			addUserIntegral() {
				this.dialogVisible = true

			}

		}
	};
</script>
