<template>
	<div>
		<div class="operation">
			<el-form :inline="true" :model="selectForm" class="demo-form-inline">

				<el-form-item>
					<el-button type="primary" @click="dialogVisible2 = true" style="margin-right: 20px;">
						<i class="el-icon-circle-plus-outline"></i>
						<span>添加商品</span>
					</el-button>
				</el-form-item>

				<el-form-item label="" label-width="0">
					<el-input v-model="goodsNameSearch" placeholder="商品名称"></el-input>
				</el-form-item>

				<!-- <el-form-item>
					<el-button type="primary" @click="selectForm.page = 1;getGoodsList();">
						查询
					</el-button>
				</el-form-item> -->


			</el-form>
		</div>

		<div class="dataList">
			<el-table :data="dataList2" border style="width: 100%;" height="100%">
				<!-- <el-table-column prop="id" label="ID" width="50"></el-table-column> -->


				<el-table-column prop="full_goods_name" label="商品名称"></el-table-column>
				<el-table-column prop="amount" label="单价"></el-table-column>
				<el-table-column prop="cost" label="成本"></el-table-column>
				<el-table-column prop="num" label="数量"></el-table-column>
				<el-table-column prop="total_amount" label="小计"></el-table-column>
				<el-table-column prop="total_cost" label="成本小计"></el-table-column>


				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button type="primary" @click="handleDetail(scope.row)">修改</el-button>
						<el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div> -->




		<el-dialog title="订单商品修改" :visible.sync="dialogVisible" center>


			<el-form label-width="120px" label-position="right">

				<el-form-item label="商品名称:">
					<el-input :value="goodsDetail.full_goods_name" :disabled="true" style="width: 60%;"></el-input>
				</el-form-item>


				<el-form-item label="单价(元):">
					<el-input v-model="goodsDetail.amount" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="成本(元):">
					<el-input v-model="goodsDetail.cost" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="数量:">
					<el-input v-model="goodsDetail.num" style="width: 60%;"></el-input>
				</el-form-item>


			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="updateInfoSubmit">修改</el-button>

				<el-button type="info" @click="dialogVisible=false">关闭</el-button>
			</div>
		</el-dialog>



		<el-dialog width="70%" title="订单商品添加或修改" :visible.sync="dialogVisible2" center>
			<el-form label-width="120px" label-position="right" style="overflow: scroll;height: 500px;">

				<el-form-item label="商品名称搜索">
					<el-input v-model="goodsNameSearch2" style="width: 300px;"></el-input>
				</el-form-item>

				<el-form-item label="商品规格:" v-for="item in goodsList" :key="item.id"
					v-show="goodsNameSearch2 == '' || item.goods_name.indexOf(goodsNameSearch2) > -1">
					<el-input v-model="item.goods_name" :disabled="true" style="width: 60%;"></el-input>
					<el-input-number v-model="item.num" :min="0" :max="1000" label="数量"></el-input-number>
				</el-form-item>

			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="goodsSave()">确认</el-button>
				<el-button @click="dialogVisible=false">关闭</el-button>
			</div>
		</el-dialog>




	</div>
</template>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	// import html2canvas from "html2canvas";

	export default {
		data() {
			return {
				orderId: 0,
				dialogVisible: false,
				dialogVisible2: false,
				innerVisible1: false,

				total: 0,
				selectForm: {
					orderId: 0,
					size: 20,
					page: 1,
					search: '',

				},


				//列表数据
				dataList: [

				],

				goodsNameSearch: "",


				goodsDetail: {},


				//添加商品用
				goodsList: [],
				goodsNameSearch2: "",
				goodsSaveList: [],


			};
		},


		computed: {

			dataList2() {
				let arr = [];

				for (let i = 0; i < this.dataList.length; i++) {

					if (this.goodsNameSearch == "" || this.dataList[i]["full_goods_name"].indexOf(this.goodsNameSearch) > -
						1) {
						arr.push(this.dataList[i])
					}

				}
				return arr;
			}

		},

		created() {
			this.orderId = this.$route.query.id
			this.selectForm.orderId = this.$route.query.id
			this.getGoodsList();
			setTimeout(() => {
				this.loadGoods();
			}, 500)

		},



		methods: {
			/**
			 * 获取列表
			 */
			getGoodsList() {
				this.$http
					.post('/manage/order/detail', {
						id: this.orderId
					})
					.then(res => {

						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							console.log(result);
							this.dataList = result.goods;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			//订单修改
			handleDetail(row) {
				this.dialogVisible = true;
				this.goodsDetail = row;
			},

			//订单修改
			updateInfoSubmit() {

				console.log(this.goodsDetail);

				this.$http
					.post('/manage/order/upGoods', this.goodsDetail)
					.then(res => {

						let {
							errcode,
							result,
							msg
						} = res;
						if (errcode === 0) {
							console.log(result);
							this.$message({
								message: '修改成功！',
								type: 'success'
							});
							this.getGoodsList();
							this.dialogVisible = false;
							// this.dataList = result.goods;
						} else {
							this.$message.error(msg);
						}
					})
					.catch(error => {
						console.log(error);
					});

			},

			handleDelete(row) {

				this.$confirm('确定是否删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.$http
						.post('/manage/order/delGoods', {
							id: row.id
						})
						.then(res => {

							let {
								errcode,
								msg
							} = res;
							if (errcode === 0) {

								this.$message({
									message: '修改成功！',
									type: 'success'
								});
								this.getGoodsList();

							} else {
								this.$message.error(msg);
							}
						})
						.catch(error => {
							console.log(error);
						});

				}).catch(() => {

				});

			},

			//初始化商品库
			loadGoods() {
				this.$http
					.post('/manage/order/allGoods')
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.goodsList = result;

							for (let i = 0; i < this.dataList.length; i++) {
								let goodsId = this.dataList[i].goods_id;
								let std1 = this.dataList[i].std1;
								let std2 = this.dataList[i].std2;
								let num = this.dataList[i].num;

								for (let j = 0; j < this.goodsList.length; j++) {
									if (this.goodsList[j].goods_id == goodsId && this.goodsList[j].std1 == std1 && this
										.goodsList[j].std2 == std2) {
										this.goodsList[j].num = num;
									}

								}

							}

						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			//商品添加或修改
			goodsSave() {
				this.goodsSaveList = [];

				for (let i in this.goodsList) {
					if (this.goodsList[i].num > 0) {

						this.goodsSaveList.push({
							goods_id: this.goodsList[i].goods_id,
							std1: this.goodsList[i].std1,
							std2: this.goodsList[i].std2,
							name: this.goodsList[i].goods_name,
							num: this.goodsList[i].num
						})

					}
				}

				if (this.goodsSaveList.length == 0) {
					this.$message.error("商品不能为空");
					return;
				}

				let param = {
					order_id: this.orderId,
					goods: this.goodsSaveList
				}
				this.$http
					.post('/manage/order/updateGoodsList', param)
					.then(res => {
						console.log(res);
						let {
							errcode,
							msg,
						} = res;
						if (errcode === 0) {
							this.$message({
								message: '添加成功！',
								type: 'success'
							});
							this.getGoodsList();
							this.dialogVisible2 = false;
						} else {
							this.$message.error(msg)
						}
					})
					.catch(error => {
						console.log(error);
					});




			}

		}
	};
</script>
