// import Vue from 'vue'
import axios from 'axios'
// import VueAxios from 'vue-axios'

import config from '../config/config.js';
import cache from './cache.js';

// Vue.use(VueAxios, axios)


axios.interceptors.request.use(function(config) {
	// 在发送请求
	return config;
}, function(error) {
	// 对请求错误
	return Promise.reject(error);
});

const get = (uri) => {
	console.log(uri);
	// return new Promise((resolve, reject) => {
	// 	axios({
	// 		baseURL: config.api_domain,
	// 		url: uri,
	// 		method: "GET",
	// 		headers: {'Authorization':'laKrUs7SVJtbapSZkmVekWSSWo1S2Yhwkm+YbGZxa5aUla1wnpuTlmtvx5jIZmLEl8OcmmjHmZfGapdoYZqck5aYZ2I='},//此处需要换成从缓存获取
	// 		timeout: config.timeout,
	// 	}).then(response => {
	// 		resolve(response.data);
	// 	}).catch(error => {
	// 		reject(error);
	// 	});
	// });
}


const post = (uri, data, is_load) => {

	console.log(is_load);

	return new Promise((resolve, reject) => {

		let token = cache.get('token');


		// if (is_load) {
		// 	uni.showLoading({
		// 		title: '加载中'
		// 	});
		// }


		console.log("--------request start-------");
		console.log(config.api_domain + uri);
		console.log(data);


		axios({
			method: 'post',
			url: config.api_domain + uri,
			headers: {
				'Authorization': token
			},
			data
		}).then(response => {

			console.log(response);

			if (response.data.errcode >= 9900) {
				//登陆校验失败 删除缓存 重新登录
				
				cache.del('token');
				
				window.location.href = "/";

			} else {
				resolve(response.data);
			}

		}).catch(error => {

			console.log(error);
			reject(error);
		});

	});
}


// const uploadImage = (filepath, data = {}) => {
// 	return new Promise((resolve, reject) => {


// 		let token = cache.get('token');

// 		if (!token) {
// 			uni.reLaunch({
// 				url: '/pages/login/login'
// 			});
// 			return;
// 		}


// 		uni.showLoading({
// 			title: '上传中'
// 		});


// 		uni.uploadFile({
// 			header: {
// 				'Authorization': token //自定义请求头信息
// 			},
// 			url: config.api_domain + '/upload/image', //仅为示例，非真实的接口地址
// 			filePath: filepath,
// 			name: 'file',
// 			formData: data,
// 			success: (uploadFileRes) => {
// 				console.log(uploadFileRes.data);

// 				if (res.data.errcode >= 9900) {
// 					//登陆校验失败 跳转登陆页面
// 					uni.reLaunch({
// 						url: '/pages/login/login'
// 					});

// 				} else {
// 					resolve(uploadFileRes.data);
// 				}


// 			},
// 			fail: (error) => {
// 				reject(error);
// 			},
// 			complete: () => {
// 				uni.hideLoading();
// 			}
// 		});




// 	});
// }


export default {
	get,
	post,
	// uploadImage
}
