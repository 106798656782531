<template>
	<el-container class="layout">
		<el-aside style="width: auto">
			<el-menu router :default-active="currentPath" class="menu" background-color="#304156" text-color="#BFCBD9" active-text-color="#FFFF00">
				<el-menu-item index="0">
					<i class="el-icon-orange"></i>
					<span slot="title">广东煲汤</span>
				</el-menu-item>


				<el-submenu index="1">
					<template slot="title">
						<i class="el-icon-pear"></i>
						<span slot="title">商品管理</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/category/list">商品分类</el-menu-item>
						<el-menu-item index="/goods/list">商品列表</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				
				<el-submenu index="5">
					<template slot="title">
						<i class="el-icon-pear"></i>
						<span slot="title">进销存</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/sku/list">物料</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<el-menu-item index="/sku/pricecount">物料成本计算</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<el-menu-item index="/sku/goodslist">商品物料绑定</el-menu-item>
					</el-menu-item-group>
				</el-submenu>

				<el-submenu index="2">
					<template slot="title">
						<i class="el-icon-apple"></i>
						<span slot="title">订单管理</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/order/list">订单列表</el-menu-item>
						<el-menu-item index="/order/create">客服下单</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				
				<el-submenu index="3">
					<template slot="title">
						<i class="el-icon-pear"></i>
						<span slot="title">小程序商城</span>
					</template>
		
					<el-menu-item-group>
						<el-menu-item index="/banner/list">轮播图</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<el-menu-item index="/notices/list">公告</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<el-menu-item index="/shopCategory/list">商城分类</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<el-menu-item index="/user/list">用户管理</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<el-menu-item index="/user/testList">用户测试</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<el-menu-item index="/activityCoupon/list">活动券</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<el-menu-item index="/exchange/list">用户兑换</el-menu-item>
					</el-menu-item-group>
				<!-- 	<el-menu-item-group>
						<el-menu-item index="/coupon/couponList">优惠券管理</el-menu-item>
					</el-menu-item-group> -->

				</el-submenu>
				
				
				<el-submenu index="4">
					<template slot="title">
						<i class="el-icon-pear"></i>
						<span slot="title">配置</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/config/edit">系统配置</el-menu-item>
					</el-menu-item-group>	
					<el-menu-item-group>
						<el-menu-item index="/testConfig/list">体质配置</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
					
				
				<el-submenu index="6">
					<template slot="title">
						<i class="el-icon-pear"></i>
						<span slot="title">经营</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/report/list">报表</el-menu-item>
					</el-menu-item-group>	
					<el-menu-item-group>
						<el-menu-item index="/cost/list">进货记录</el-menu-item>
					</el-menu-item-group>	
				</el-submenu>
				
	

			<!-- 	<el-submenu index="3">
					<template slot="title">
						<i class="el-icon-lollipop"></i>
						<span slot="title">用户管理</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="3-1">导航1</el-menu-item>
						<el-menu-item index="3-2">导航2</el-menu-item>
					</el-menu-item-group>
				</el-submenu> -->
			</el-menu>
		</el-aside>
		<el-container>
			<el-header></el-header>
			<el-main>
				<router-view></router-view>
			</el-main>
			<el-footer>
				©广东煲汤版权所有
				</el-footer>
		</el-container>
	</el-container>
</template>

<script>
export default {
	data() {
		return {
		
		};
	},
	
	methods: {

	},
	computed:{
		currentPath(){
			return this.$route.path;
		}
	}
};
</script>

<style>
.layout {
	height: 100vh;
}

.el-header{
	/* background-color: #b3c0d1; */
	color: #333;
	text-align: center;
	border-bottom: 2px solid rgb(48, 65, 86);;
	
}
.el-footer {
	
		/* background-color: #b3c0d1; */
	color: #333;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px
	/* line-height: 60px; */
}

.el-aside {
	/* background-color: #d3dce6; */
	background-color: rgb(48, 65, 86);
	color: rgb(191, 203, 217);
	/* text-align: center; */
	/* line-height: 200px; */
}

.el-main {
	/* 	background-color: #e9eef3;
	color: #333;
	text-align: center;
	line-height: 160px; */
}

body > .el-container {
	/* margin-bottom: 40px; */
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
	line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
	line-height: 320px;
}

.el-menu-item,
.el-submenu__title {
	font-size: 14px !important;
}

.menu:not(.el-menu--collapse) {
	width: 200px;
	min-height: 400px;
}

.el-menu-item.is-active{
	background-color: #1F2D3D !important;
}
</style>
