<template>
	<div>

		<div class="operation">

			<el-form :inline="true" :model="selectForm" class="demo-form-inline">
				<el-form-item label="" label-width="0">
					<el-input v-model="selectForm.name" placeholder="分类名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="selectForm.page = 1;getList();">
						查询
					</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addUserCoupon" style="margin-right: 20px;">
						<i class="el-icon-circle-plus-outline"></i>
						<span>派发优惠券</span>
					</el-button>
				</el-form-item>
			</el-form>

		</div>


		<div class="dataList">
			<el-table :data="dataList" border style="width: 100%;" height="100%">
				<el-table-column prop="id" label="ID" width="100"></el-table-column>

				<el-table-column prop="nickname" label="用户"></el-table-column>
				<el-table-column prop="name" label="名称"></el-table-column>
				<el-table-column prop="amount" label="面值"></el-table-column>

				<el-table-column label="图片">
					<template slot-scope="scope">
						<img :src="scope.row.image" height="25" />
					</template>
				</el-table-column>



				<el-table-column prop="create_time" label="获取时间"></el-table-column>
				<el-table-column prop="expires" label="有效期至"></el-table-column>
				<el-table-column prop="status_des" label="是否使用"></el-table-column>



				<!-- 		<el-table-column label="操作" width="300">
					<template slot-scope="scope">
						<el-button type="primary" @click="handleShowCoupon(scope.row)">优惠券</el-button>
					
						<el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>

		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div>

		<el-dialog title="派发优惠券" :visible.sync="dialogVisible" center>
			<el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="right">
				<el-form-item label="优惠券模板:" prop="couponId">

					<el-select v-model="dataForm.couponId" placeholder="请选择">
						<el-option v-for="item in allCoupon" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>

				</el-form-item>

				<el-form-item label="数量:" prop="number">
					<el-input v-model="dataForm.number"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="submitForm()">确认</el-button>
				<el-button @click="resetForm()">重置</el-button>
			</div>
		</el-dialog>






	</div>
</template>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	export default {
		data() {
			return {

				user_id: 0,

				total: 0,
				selectForm: {
					size: 20,
					page: 1,
					user_id: 0,
				},

				dialogVisible: false,


				//列表数据
				dataList: [

				],


				//表单数据
				dataForm: {
					userId: 0, 
					couponId: '',
					number: 1,

				},
				//表单验证规则
				rules: {
					number: [{
						required: true,
						message: '请输入数量',
						trigger: 'blur'
					}],
					couponId: [{
						required: true,
						message: '请选择优惠券',
						trigger: 'blur'
					}]

				},


				allCoupon: [],


			};
		},

		created() {
			
	
			
			if(this.$route.query.user_id){
				this.user_id = this.$route.query.user_id;
				this.dataForm.userId = this.$route.query.user_id;
				this.selectForm.user_id = this.$route.query.user_id;
				
				this.getList();
			}


			
		},

		methods: {
			/**
			 * 获取列表
			 */
			getList() {
				this.$http
					.post('/manage/user/couponList', this.selectForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.dataList = result.list;
							this.total = result.total;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			/**
			 * 表单提交
			 */
			submitForm() {
				this.$refs['ruleForm'].validate(valid => {
					if (valid) {
						console.log(this.dataForm);

						this.$http
							.post('/manage/user/addCoupon', this.dataForm)
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								console.log(errcode);

								if (errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									});
									
									this.dialogVisible = false;
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			/**
			 * 表单重置
			 */
			resetForm() {
				this.dataForm = {
					userId: 0,
					couponId: '',
					number: 1,
				};
				this.$refs['ruleForm'].resetFields();
			},

			/**
			 * 删除
			 * @param {Object} row
			 */
			handleDelete(row) {
				console.log(row);

				this.$confirm('删除【' + row.name + '】, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.post('/manage/shopCategory/del', {
								id: row.id
							})
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								if (errcode == 0) {
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					})
					.catch(() => {});
			},

			/**
			 * 查看详情
			 * @param {Object} e
			 */
			handleEdit(row) {
				console.log(row);
				this.dataForm = JSON.parse(JSON.stringify(row));
				this.dialogVisible = true;
			},



			/**
			 * 分页改变
			 * @param {Object} e
			 */
			handleCurrentChange(e) {
				this.selectForm.page = e;
				this.getList();
			},

			/**
			 * 派发优惠券
			 */
			addUserCoupon() {
				this.dialogVisible = true


				this.$http
					.post('/manage/coupon/allCoupon')
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.allCoupon = result.list;

						}
					})
					.catch(error => {
						console.log(error);
					});
			}

		}
	};
</script>
