<template>
	<div style="width: 60%;">
		<el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="180px" label-position="right">
			<el-form-item label="用户标识(临时 建议手机):" prop="user_number">
				<el-input v-model="dataForm.user_number" style="width: 250px;"></el-input>
			</el-form-item>
			
			<el-form-item label="用户编码ID(优先使用):" prop="user_num">
				<el-input v-model="dataForm.user_num" style="width: 250px;" type="number"></el-input>
			</el-form-item>
			
			<el-form-item label="运费(元):" prop="send_amount">
				<el-input v-model="dataForm.send_amount" style="width: 250px;" type="number"></el-input>
			</el-form-item>

			<el-form-item label="订单备注" prop="remark">
				<el-input type="textarea" v-model="dataForm.remark"></el-input>
			</el-form-item>

			<el-form-item label="收货人姓名" prop="to_full_name">
				<el-input v-model="dataForm.to_full_name" style="width: 250px;"></el-input>
			</el-form-item>

			<el-form-item label="收货人手机" prop="to_phone">
				<el-input v-model="dataForm.to_phone" style="width: 250px;"></el-input>
			</el-form-item>

			<el-form-item label="收货人地址" prop="to_full_address">
				<el-input type="textarea" v-model="dataForm.to_full_address"></el-input>
			</el-form-item>

			<el-form-item label="已选商品" v-if="dataForm.goods.length > 0">

				<div style="display: flex; align-items: center;" v-for="(item, index) in dataForm.goods" :key="index">
					<el-input style="width: 80%; margin-right: 10px;" :disabled="true"
						:value="item.name+ ' - 数量:'+item.num"></el-input>
					<i class="el-icon-remove" style="font-size: 24px; color: #ff0000;" @click="removeGoods(index)"></i>
				</div>

			</el-form-item>

			<el-form-item>
				<el-button type="primary" size="mini" @click="dialogVisible = true">添加商品</el-button>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="submitForm()">下单</el-button>
				<el-button @click="resetForm()">重置</el-button>
			</el-form-item>

		</el-form>


		<el-dialog width="70%" title="添加订单商品" :visible.sync="dialogVisible" center>
			<el-form label-width="120px" label-position="right" style="overflow: scroll;height: 500px;" >
				
				<el-form-item label="商品名称搜索">
					<el-input v-model="goodsNameSearch" style="width: 300px;"></el-input>
				</el-form-item>
				
				<el-form-item label="商品规格:" v-for="item in goodsList" :key="item.id" v-show="goodsNameSearch == '' || item.goods_name.indexOf(goodsNameSearch) > -1">
					<el-input v-model="item.goods_name" :disabled="true" style="width: 60%;"></el-input>
					<el-input-number v-model="item.num" :min="0" :max="1000" label="数量"></el-input-number>
				</el-form-item>
		
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="goodsConfim()">确认</el-button>
				<el-button @click="dialogVisible=false">关闭</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				goodsNameSearch:"",
				goodsList: [],

				dataForm: {
					user_number: "",
					remark: "",
					to_full_name: "",
					to_phone: "",
					to_full_address: "",
					user_num:"",
					send_amount:8,
					goods: []
				},

				rules: {
					// to_full_name: [{
					// 	required: true,
					// 	message: '请输入客户(收货人)姓名',
					// 	trigger: 'blur'
					// }],
					// to_phone: [{
					// 	required: true,
					// 	message: '请输入客户(收货人)电话',
					// 	trigger: 'blur'
					// }],
					// to_full_address: [{
					// 	required: true,
					// 	message: '请输入客户(收货人)地址',
					// 	trigger: 'blur'
					// }]

				},

			};
		},

		created() {
			this.loadGoods();
		},

		methods: {

			loadGoods() {
				this.$http
					.post('/manage/order/allGoods')
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.goodsList = result;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},

			//商品确认
			goodsConfim() {

				this.dataForm.goods = [];

				for (let i in this.goodsList) {
					if (this.goodsList[i].num > 0) {

						this.dataForm.goods.push({
							id: this.goodsList[i].id,
							name: this.goodsList[i].goods_name,
							num: this.goodsList[i].num
						})

					}
				}


				this.dialogVisible = false;
			},

			//确认下单
			submitForm() {

				this.$http
					.post('/manage/order/save', this.dataForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							msg
						} = res;
						if (errcode === 0) {
							
							for (let i in this.goodsList) {
								this.goodsList[i].num  = 0; 
							}
							
							this.resetForm();

							this.$message({
								message: '下单成功！',
								type: 'success'
							});
						}else{
							this.$message({
								message: msg,
								type: 'error'
							});
						}
					})
					.catch(error => {
						console.log(error);
					});
			},

			//重置表单
			resetForm() {
				this.$refs['ruleForm'].resetFields();
				this.dataForm.goods = [];
			},

			removeGoods(index) {
				this.dataForm.goods.splice(index, 1);
			}

		}
	};
</script>

<style>
</style>
