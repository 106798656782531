<template>
	<div>
		<div class="operation">
			<el-form :inline="true" :model="selectForm" class="demo-form-inline">
				<el-form-item label="" label-width="0">
					<el-input v-model="selectForm.search" placeholder="收货人"></el-input>
				</el-form-item>
				<el-form-item label="" label-width="0">
					<el-select v-model="selectForm.status" placeholder="状态">
						<el-option key="1000" label="全部" value="1000"></el-option>
						<el-option key="-1" label="已取消" value="-1"></el-option>
						<el-option key="0" label="未付款" value="0"></el-option>
						<el-option key="1" label="已付款未发货" value="1"></el-option>
						<el-option key="2" label="已发货" value="2"></el-option>
						<el-option key="10" label="已完成" value="10"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="selectForm.page = 1;getList();">
						查询
					</el-button>
				</el-form-item>
			</el-form>
		</div>

		<div class="dataList">
			<el-table :data="dataList" border style="width: 100%;" height="100%">
				<!-- <el-table-column prop="id" label="ID" width="50"></el-table-column> -->
				<el-table-column prop="user_id" label="用户ID" width="60"></el-table-column>
				<el-table-column prop="user_num" label="用户编码ID" width="70"></el-table-column>
				<el-table-column prop="order_no" label="订单号"></el-table-column>
				<el-table-column prop="create_time" label="下单时间"></el-table-column>
				<el-table-column prop="goods_amount" label="商品" width="80"></el-table-column>
				<el-table-column prop="send_amount" label="运费" width="70"></el-table-column>
				<el-table-column prop="amount" label="总金额" width="80"></el-table-column>
				<el-table-column prop="pay_amount" label="已付" width="80"></el-table-column>
				<el-table-column prop="status_des" label="状态" width="70"></el-table-column>
				<el-table-column prop="to_full_name" label="收货人" width="80"></el-table-column>
				<el-table-column prop="to_phone" label="电话" width="120"></el-table-column>
				<el-table-column prop="to_full_address" label="地址"></el-table-column>
				<el-table-column prop="cost" label="三方成本"></el-table-column>
				<el-table-column prop="skuCost" label="物料成本"></el-table-column>


				<el-table-column label="操作" width="150">
					<template slot-scope="scope">

						<el-dropdown>
							<el-button type="primary">
								菜单<i class="el-icon-arrow-down el-icon--right"></i>
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item><el-button type="primary" size="mini" @click="handleEdit(scope.row.id)">操作</el-button></el-dropdown-item>
								<el-dropdown-item><el-button type="primary" size="mini" @click="handleDetail(scope.row.id)">商品</el-button></el-dropdown-item>
								<el-dropdown-item><el-button type="primary" size="mini" @click="handleExpress(scope.row)">物流</el-button></el-dropdown-item>
								<el-dropdown-item><el-button type="primary" size="mini" @click="handleCollocation(scope.row)">食谱</el-button></el-dropdown-item>
								<el-dropdown-item><el-button type="primary" size="mini" @click="handlePay(scope.row)">支付</el-button></el-dropdown-item>
								<el-dropdown-item><el-button type="primary" size="mini" @click="handleDabao (scope.row)">打包单</el-button></el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>

				<!-- 		<el-button type="primary" size="mini" @click="handleEdit(scope.row.id)">操作</el-button>
						<el-button type="primary" size="mini" @click="handleDetail(scope.row.id)">商品</el-button>
						<el-button type="primary" size="mini" @click="handleExpress(scope.row)">物流</el-button>
						<el-button type="primary" size="mini" @click="handleCollocation(scope.row)">食谱</el-button>
						<el-button type="primary" size="mini" @click="handlePay(scope.row)">支付</el-button> -->
						<!-- <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div>




		<el-dialog title="订单详情" :visible.sync="dialogVisible" center width="70%" :close-on-click-modal="false">


			<el-form label-width="120px" label-position="right">

				<el-form-item label="用户编码ID:">
					<el-input v-model="orderDetail.user_num" type="number" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="用户ID:">
					<el-input :value="orderDetail.user_id" :disabled="true" style="width: 60%;"></el-input>
				</el-form-item>



				<el-form-item label="订单号:">
					<el-input :value="orderDetail.order_no" readonly="true" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="下单时间:">
					<el-input :value="orderDetail.create_time" :disabled="true" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="商品金额(元):">
					<el-input :value="orderDetail.goods_amount" :disabled="true" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="配送费(元):">
					<el-input v-model="orderDetail.send_amount" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="优惠金额(元):">
					<el-input :value="orderDetail.coupon_amount" :disabled="true" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="订单金额(元):">
					<el-input :value="orderDetail.amount" :disabled="true" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="已付金额(元):">
					<el-input :value="orderDetail.pay_amount" :disabled="true" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="收货人:">
					<el-input v-model="orderDetail.to_full_name" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="电话:">
					<el-input v-model="orderDetail.to_phone" style="width: 60%;"></el-input>
				</el-form-item>

				<el-form-item label="地址:">
					<el-input v-model="orderDetail.to_full_address"></el-input>
				</el-form-item>

				<el-form-item label="备注:">
					<el-input :value="orderDetail.remark" readonly="true"></el-input>
				</el-form-item>

				<el-form-item :label="'商品'+(k+1)" v-for="item,k in goodsDetail" :key="item.id">
					<el-input
						:value="item.goods_backups.name + '【'+item.goods_specs_backups.std1+item.goods_specs_backups.std2+'】'"
						:disabled="true" style="width: 60%;"></el-input>
					<el-input-number v-model="item.num" :disabled="true" label="数量"></el-input-number>
					<el-link type="primary" @click="labelPrint(item.id)">打印</el-link>
				</el-form-item>



			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="updateInfo">订单修改</el-button>
				<el-button type="success" @click="innerVisible1=true" v-if="orderDetail.status != -1">收款</el-button>
				<el-button type="primary" @click="submitStatus(2)"
					v-if="orderDetail.status != -1 && orderDetail.status < 2">发货</el-button>
				<el-button type="primary" @click="submitStatus(10)"
					v-if="orderDetail.status >=2 && orderDetail.status < 10 && orderDetail.status != -1">完成</el-button>

				<el-button type="danger" @click="submitStatus(-1)"
					v-if="orderDetail.status < 2 && orderDetail.status != -1">取消订单</el-button>
				<el-button type="warning" @click="innerVisible2=true">销售单</el-button>
				<el-button type="warning" @click="innerVisible3=true">采购单</el-button>

				<el-button type="primary" @click="collocation()">搭配表</el-button>

				<el-button type="primary" @click="labelPrint()">标签打印</el-button>

				<el-button type="info" @click="dialogVisible=false">关闭</el-button>
			</div>
		</el-dialog>


		<el-dialog width="30%" title="收付款" :visible.sync="innerVisible1" append-to-body>
			<el-form label-width="120px" label-position="right">
				<el-form-item label="订单总额(元)">
					<el-input :value="orderDetail.amount" :disabled="true" style="width: 60%;"></el-input>
				</el-form-item>
				<el-form-item label="已付金额(元)">
					<el-input :value="orderDetail.pay_amount" :disabled="true" style="width: 60%;"></el-input>
				</el-form-item>
				<el-form-item label="收款金额(元)">
					<el-input v-model="pay_amount" style="width: 60%;"></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer">
				<el-button type="primary" @click="submitPayForm()">确认</el-button>
				<el-button type="info" @click="innerVisible1 = false">关闭</el-button>
			</div>
		</el-dialog>

		<el-dialog width="500px" title="销售单" :visible.sync="innerVisible2" append-to-body>
			<div ref="imageWrapper" style="padding: 10px; font-size: 14px;">
				<div style="display: flex;  justify-content: space-between;">
					<div>
						<img src="../../assets/log.png" width="60" height="60" />
						<div>
							<h2 style="padding: 5px 0;">广东煲汤</h2>
							<!-- <div style="padding: 5px 0;">https://www.guangdongbaotang.com</div> -->
						</div>
					</div>

					<div style="font-size: 28px;">
						销售单
					</div>
				</div>

				<div style="border-bottom: 2px solid #333333; padding: 10px 0;">
					<div style="padding: 5px 0;">
						<h4>基本信息</h4>
					</div>
					<div style="display: flex; justify-content: space-between;padding: 2px 0;">
						<span>客户名称：{{orderDetail.to_full_name}}</span>
						<span>销售时间：{{orderDetail.create_time}}</span>
					</div>

					<div style="padding: 2px 0;">
						<span>备注：{{orderDetail.remark}}</span>
					</div>

				</div>


				<div style="">
					<el-table :data="goodsDetail" style="width: 100%" show-summary sum-text="商品合计">
						<el-table-column prop="index" label="序号" width="80">

						</el-table-column>
						<el-table-column prop="goods_name" label="品名">
							<template slot-scope="scope">
								<span>{{ scope.row.goods_backups.name + '【'+scope.row.goods_specs_backups.std1+scope.row.goods_specs_backups.std2+'】' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="num" label="数量" width="48">
						</el-table-column>
						<el-table-column prop="amount" label="单价" width="70">

						</el-table-column>
						<el-table-column prop="total_amount" label="金额" width="80">
						</el-table-column>
					</el-table>
				</div>

				<div>
					<div style="padding: 10px 0;">
						<h4>结算</h4>
					</div>
					<!-- 		<div style="display: flex; justify-content: space-between;">
						<span>商品金额：{{orderDetail.goods_amount}}</span>
						<span>运费：{{orderDetail.send_amount}}</span>
						<span>总计：{{orderDetail.amount}}</span>
					</div> -->
					<div style="display: flex; justify-content: space-between;">
						<span>商品：{{orderDetail.goods_amount}}</span>
						<span>运费：{{orderDetail.send_amount}}</span>
						<span>总计：{{orderDetail.amount}}</span>
						<span>已付：{{orderDetail.pay_amount}}</span>
						<!-- <span>待付金额：0</span> -->
					</div>
				</div>

			</div>

			<div slot="footer">
				<el-button type="primary" @click="orderPrintImg(1)">保存图片</el-button>
				<!-- <el-button type="primary" @click="orderPrint()">打印</el-button> -->
				<el-button type="info" @click="innerVisible2 = false">关闭</el-button>
			</div>
		</el-dialog>


		<el-dialog width="500px" title="采购单" :visible.sync="innerVisible3" append-to-body>
			<div ref="imageWrapper2" style="padding: 10px; font-size: 14px;">

				<div style="border-bottom: 2px solid #333333; padding: 10px 0;">
					<div style="display: flex; justify-content: space-between;">
						<span>收件人：{{orderDetail.to_full_name}}</span>
					</div>
				</div>


				<div style="">
					<el-table :data="goodsDetail" style="width: 100%" show-summary>
						<el-table-column prop="index" label="序号" width="50">

						</el-table-column>
						<el-table-column prop="goods_name" label="品名">
							<template slot-scope="scope">
								<span>{{ scope.row.goods_backups.name + '【'+scope.row.goods_specs_backups.std1+scope.row.goods_specs_backups.std2+'】' }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="num" label="数量" width="50">
						</el-table-column>
						<el-table-column prop="cost" label="单价" width="70">

						</el-table-column>
						<el-table-column prop="total_cost" label="小计" width="80">
						</el-table-column>
					</el-table>
				</div>

			</div>

			<div slot="footer">
				<el-button type="primary" @click="orderPrintImg(2)">保存图片</el-button>
				<!-- <el-button type="primary" @click="orderPrint()">打印</el-button> -->
				<el-button type="info" @click="innerVisible3 = false">关闭</el-button>
			</div>
		</el-dialog>


		<el-dialog width="50%" title="物流" :visible.sync="innerVisible4" append-to-body :close-on-click-modal="false">
			<el-form label-width="120px" label-position="right">

				<el-form-item label="承运商">


					<el-select v-model="express_company" placeholder="请选择">
						<el-option v-for="item in express_company_list" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="运单号">
					<el-input v-model="express_no" style="width: 60%;"></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer">
				<el-button type="primary" @click="submitExpressForm()">确认</el-button>
				<el-button type="info" @click="innerVisible4 = false">关闭</el-button>
			</div>
		</el-dialog>

		<el-dialog width="50%" title="小程序食谱链接" :visible.sync="innerVisible5" append-to-body
			:close-on-click-modal="false">
			<el-form label-width="120px" label-position="right">
				<el-form-item label="链接">
					<el-input v-model="wxLink" style="width: 60%;"></el-input>
				</el-form-item>

			</el-form>

			<div>注意：后台的订单，需要微信手机号和收货手机一致才有效</div>

			<div slot="footer">
				<el-button type="info" @click="innerVisible5 = false">关闭</el-button>
			</div>
		</el-dialog>

		<el-dialog width="50%" title="小程序支付链接" :visible.sync="innerVisible6" append-to-body
			:close-on-click-modal="false">
			<el-form label-width="120px" label-position="right">
				<el-form-item label="链接">
					<el-input v-model="wxPayLink" style="width: 60%;"></el-input>
				</el-form-item>

			</el-form>

			<div>注意：首次打开的用户才有效，同个订单无法多个用户同时打开链接</div>

			<div slot="footer">
				<el-button type="info" @click="innerVisible6 = false">关闭</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	import html2canvas from "html2canvas";

	export default {
		data() {
			return {
				dialogVisible: false,
				innerVisible1: false,
				innerVisible2: false,
				innerVisible3: false,
				innerVisible4: false,
				innerVisible5: false,
				innerVisible6: false,
				total: 0,
				selectForm: {
					size: 20,
					page: 1,
					search: '',
					status: "1000",
				},


				//列表数据
				dataList: [

				],

				//订单详情
				orderDetail: {},
				goodsDetail: {},

				pay_amount: 0,

				express_company_list: [{
						value: '圆通速递',
						label: '圆通速递'
					}, {
						value: '韵达快递',
						label: '韵达快递'
					}, {
						value: '申通快递',
						label: '申通快递'
					}, {
						value: '中通快递',
						label: '中通快递'
					}, {
						value: '顺丰速运',
						label: '顺丰速运'
					}, {
						value: 'EMS',
						label: 'EMS'
					}, {
						value: '京东物流',
						label: '京东物流'
					}, {
						value: '极兔速递',
						label: '极兔速递'
					},

				],

				order_id: 0,
				express_no: "",
				express_company: "",

				wxLink: "",
				wxPayLink: "",
			};
		},

		created() {
			this.getList();
		},

		methods: {
			/**
			 * 获取列表
			 */
			getList() {
				this.$http
					.post('/manage/order/list', this.selectForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.dataList = result.list;
							this.total = result.total;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			/**
			 * 分页改变
			 * @param {Object} e
			 */
			handleCurrentChange(e) {
				this.selectForm.page = e;
				this.getList();
			},

			/**
			 * 查看详情
			 * @param {Object} id
			 */
			handleEdit(id) {

				this.$http
					.post('/manage/order/detail', {
						id: id
					})
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.orderDetail = result.order;
							this.goodsDetail = result.goods;

							this.dialogVisible = true;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			handleDetail(id) {
				this.$router.push({
					path: '/order/goodsList',
					query: {
						id
					}
				})
			},

			//提交收款
			submitPayForm() {
				this.$http
					.post('/manage/order/payAmount', {
						id: this.orderDetail.id,
						pay_amount: this.pay_amount
					})
					.then(res => {
						console.log(res);
						let {
							errcode,
							msg,

						} = res;
						if (errcode === 0) {

							this.handleEdit(this.orderDetail.id);
							this.getList();
							this.innerVisible1 = false;
							this.$message({
								message: '操作成功！',
								type: 'success'
							});

						} else {
							this.$message.error(msg);
						}
					})
					.catch(error => {
						console.log(error);
					});

			},

			submitStatus(status) {

				this.$confirm('确定是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http
						.post('/manage/order/upStatus', {
							id: this.orderDetail.id,
							status: status
						})
						.then(res => {
							console.log(res);
							let {
								errcode,
								msg,

							} = res;
							if (errcode === 0) {

								this.handleEdit(this.orderDetail.id);
								this.getList();
								this.$message({
									message: '操作成功！',
									type: 'success'
								});

							} else {
								this.$message.error(msg);
							}
						})
						.catch(error => {
							console.log(error);
						});
				}).catch(() => {

				});


			},

			//保存图片
			orderPrintImg(type) {
				console.log(1);

				// 设置放大倍数
				// const scale = window.devicePixelRatio;


				const ops = {
					scale: 3,
					// width,
					// height,
					useCORS: true,
					allowTaint: false,
				};

				if (type == 1) {

					html2canvas(this.$refs.imageWrapper, ops).then(canvas => {
						let dataURL = canvas.toDataURL("image/png");
						console.log(dataURL);

						const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
						save_link.href = dataURL;
						save_link.download = "广东煲汤销售单_" + this.orderDetail.to_full_name + "_" + this.orderDetail
							.create_time;

						const event = document.createEvent('MouseEvents');
						event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false,
							false,
							0, null);
						save_link.dispatchEvent(event);

						// this.imgUrl = dataURL;
						// if (this.imgUrl !== "") {
						// 	this.dialogTableVisible = true;
						// }
					});
				} else if (type == 2) {

					html2canvas(this.$refs.imageWrapper2, ops).then(canvas => {
						let dataURL = canvas.toDataURL("image/png");
						console.log(dataURL);

						const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
						save_link.href = dataURL;
						save_link.download = "采购单_" + this.orderDetail.to_full_name + "_" + this.orderDetail
							.create_time;

						const event = document.createEvent('MouseEvents');
						event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false,
							false,
							0, null);
						save_link.dispatchEvent(event);


					});

				}


			},


			//订单修改
			updateInfo() {

				this.$http
					.post('/manage/order/upInfo',
						this.orderDetail
					)
					.then(res => {
						console.log(res);
						let {
							errcode,
							msg,

						} = res;
						if (errcode === 0) {

							this.handleEdit(this.orderDetail.id);
							this.getList();
							this.$message({
								message: '修改成功！',
								type: 'success'
							});

						} else {
							this.$message.error(msg);
						}
					})
					.catch(error => {
						console.log(error);
					});
			},

			//导出搭配表
			collocation() {

				this.$confirm('导出搭配表时间较久，请勿关闭窗口?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http
						.post('/manage/order/exportCollocation', {
							id: this.orderDetail.id,
						})
						.then(res => {
							console.log(res);
							let {
								errcode,
								msg,
								result
							} = res;
							if (errcode === 0) {

								window.open(result.url)

							} else {
								this.$message.error(msg);
							}
						})
						.catch(error => {
							console.log(error);
						});
				}).catch(() => {

				});


			},


			//标签打印
			labelPrint(orders_goods_id) {

				if (!orders_goods_id)
					orders_goods_id = 0;

				this.$confirm('商品多时打印时间较久，请勿关闭窗口', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http
						.post('/manage/order/labelPrint', {
							id: this.orderDetail.id,
							orders_goods_id: orders_goods_id
						})
						.then(res => {
							console.log(res);
							let {
								errcode,
								msg,
							} = res;
							if (errcode === 0) {

								this.$message({
									message: '操作成功！',
									type: 'success'
								});

							} else {
								this.$message.error(msg);
							}
						})
						.catch(error => {
							console.log(error);
						});
				}).catch(() => {

				});
			},

			orderPrint() {
				//未好
				var newstr = this.$refs.imageWrapper.innerHTML;
				var oldstr = document.body.innerHTML;
				document.body.innerHTML = newstr;
				window.print();
				document.body.innerHTML = oldstr;
				return false;
			},

			//物流弹窗
			handleExpress(row) {
				this.order_id = row.id;
				this.express_no = row.express_no;
				this.express_company = row.express_company;
				this.innerVisible4 = true;
			},



			//物流提交
			submitExpressForm() {
				this.$confirm('请核对承运商与运单号是否正确', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http
						.post('/manage/order/updateExpress', {
							id: this.order_id,
							express_no: this.express_no,
							express_company: this.express_company,
						})
						.then(res => {
							console.log(res);
							let {
								errcode,
								msg,

							} = res;
							if (errcode === 0) {

								this.$message({
									message: '修改成功！',
									type: 'success'
								});

							} else {
								this.$message.error(msg);
							}
						})
						.catch(error => {
							console.log(error);
						});
				}).catch(() => {

				});

			},


			//食谱链接弹窗
			handleCollocation(row) {

				this.$http
					.post('/manage/order/getCollocationLink', {
						id: row.id,

					})
					.then(res => {
						console.log(res);
						let {
							errcode,
							msg,
							result
						} = res;
						if (errcode === 0) {

							this.wxLink = result;

							this.innerVisible5 = true;

						} else {
							this.$message.error(msg);
						}
					})
					.catch(error => {
						console.log(error);
					});


			},


			//支付链接弹窗
			handlePay(row) {

				this.$http
					.post('/manage/order/getPayLink', {
						id: row.id,

					})
					.then(res => {
						console.log(res);
						let {
							errcode,
							msg,
							result
						} = res;
						if (errcode === 0) {

							this.wxPayLink = result;

							this.innerVisible6 = true;

						} else {
							this.$message.error(msg);
						}
					})
					.catch(error => {
						console.log(error);
					});


			},
			
			handleDabao(row){
				this.$router.push({
					path: '/order/dabao',
					query: {
						id:row.id
					}
				})
			}

		}
	};
</script>
