<template>
	<div>
		<div class="operation">
			<el-form :inline="true" :model="selectForm" class="demo-form-inline">
				<el-form-item label="" label-width="0">
					<el-input style="width: 300px;" v-model="selectForm.user_id" placeholder="用户ID"></el-input>
					<el-input style="width: 300px;" v-model="selectForm.exchange_no" placeholder="兑换码"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="selectForm.page = 1;getList();">
						查询
					</el-button>
				</el-form-item>
			<!-- 	<el-form-item>
					<el-button type="primary" @click="dialogVisible = true" style="margin-right: 20px;">
						<i class="el-icon-circle-plus-outline"></i>
						<span>添加分类</span>
					</el-button>
				</el-form-item> -->
			</el-form>
		</div>

		<div class="dataList">
			<el-table :data="dataList" border style="width: 100%;" height="100%">
				<el-table-column prop="id" label="ID" width="50"></el-table-column>
				
				<el-table-column prop="nickname" label="用户"></el-table-column>
				<el-table-column prop="des" label="兑换项目"></el-table-column>
				<el-table-column prop="exchange_no" label="兑换编码"></el-table-column>
				<el-table-column prop="integral" label="使用枣仁"></el-table-column>
				<el-table-column prop="status_des" label="状态"></el-table-column>
				<el-table-column prop="create_time" label="时间"></el-table-column>

				
				


				<el-table-column label="操作" width="300">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status == 1" type="primary" size="mini" @click="handleCheck(scope.row)">已处理</el-button>
						<!-- <el-button type="primary" size="mini" @click="handleShowIntegral(scope.row)">枣仁</el-button> -->
					
						<!-- <el-button type="danger" @click="handleCheck(scope.row)">拒绝</el-button> -->
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div>





	</div>
</template>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	export default {
		data() {
			return {
				total: 0,
				selectForm: {
					size: 20,
					page: 1,
					user_id: '',
					exchange_no:''
				},

				dialogVisible: false,


				//列表数据
				dataList: [
			
				],

			};
		},

		created() {

			this.getList();
		},

		methods: {
			/**
			 * 获取列表
			 */
			getList() {
				this.$http
					.post('/manage/exchange/getList', this.selectForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.dataList = result.list;
							this.total = result.total;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},

			
			changeIsSafeMember(e,userId){
				this.$http
					.post('/manage/user/changeIsSafeMember', {userId:userId, isSafeMember:e})
					.then(res => {
						let {
							errcode,
							msg
						} = res;
				
	
						if (errcode != 0) {
							this.$message.error(msg);
						}
					})
					.catch(() => {
						this.$message.error('保存失败');
					});
			},




			/**
			 * 删除
			 * @param {Object} row
			 */
			handleCheck(row) {

				this.$confirm('确认已处理【' + row.exchange_no + '】相关事项, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.post('/manage/exchange/check', {
								id: row.id
							})
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								if (errcode == 0) {
									this.$message({
										type: 'success',
										message: '操作成功!'
									});
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					})
					.catch(() => {});
			},

			



			/**
			 * 分页改变
			 * @param {Object} e
			 */
			handleCurrentChange(e) {
				this.selectForm.page = e;
				this.getList();
			},


			
		}
	};
</script>
