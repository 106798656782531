<template>
	<div>
		<div class="operation">
			<el-form :inline="true" :model="selectForm" class="demo-form-inline">
				<el-form-item label="" label-width="0">
					<el-input v-model="selectForm.search" placeholder="优惠券名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="selectForm.page = 1;getList();">
						查询
					</el-button>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="dialogVisible = true" style="margin-right: 20px;">
						<i class="el-icon-circle-plus-outline"></i>
						<span>添加</span>
					</el-button>
				</el-form-item>
			</el-form>
		</div>

		<div class="dataList">
			<el-table :data="dataList" border style="width: 100%;" height="100%">
				<el-table-column prop="id" label="ID" width="50"></el-table-column>

				<el-table-column prop="name" label="名称"></el-table-column>
				<el-table-column prop="types_des" label="类型" width="70"></el-table-column>
				<el-table-column prop="values" label="面额" width="80"></el-table-column>


				<el-table-column label="生效" width="60">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
							@change="changeStatus(scope.row.status,scope.row.id)"></el-switch>
					</template>
				</el-table-column>


				<el-table-column label="背景图">
					<template slot-scope="scope">
						<img :src="scope.row.activity_image" height="25" />
					</template>
				</el-table-column>


				<el-table-column prop="expires" label="活动有效期"></el-table-column>
				<el-table-column prop="rec_num" label="领取人数"></el-table-column>
				<el-table-column prop="create_time" label="添加时间"></el-table-column>
				<el-table-column prop="link" label="链接"></el-table-column>



				<el-table-column label="操作" width="250">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
						<el-button type="primary" size="mini" @click="handleBuildLink(scope.row)">生成链接</el-button>
						<el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="page">
			<el-pagination background layout="prev, pager, next" :page-size="selectForm.size" :total="total"
				@current-change="handleCurrentChange"></el-pagination>
		</div>

		<el-dialog title="添加/编辑" :visible.sync="dialogVisible" center>
			<el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="180px" label-position="right">
				<el-form-item label="优惠券名称:" prop="name">
					<el-input v-model="dataForm.name"></el-input>
				</el-form-item>

				<el-form-item label="活动截至时间(最多30天):" prop="expires">
					<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm.expires" type="datetime"
						placeholder="选择日期时间"></el-date-picker>
				</el-form-item>

				<el-form-item label="类型" prop="types">
					<el-radio-group v-model="dataForm.types">
						<el-radio :label="1">金额券</el-radio>
						<el-radio :label="2">折扣券</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="金额:" prop="amount">
					<el-input v-model="dataForm.amount" type="number" placeholder="元"></el-input>
				</el-form-item>

				<el-form-item label="折扣:" prop="discount">
					<el-input v-model="dataForm.discount" type="number" placeholder="折扣最多保留一位小数,例如9.5"></el-input>
				</el-form-item>

				<el-form-item label="使用门槛:" prop="use_amount">
					<el-input v-model="dataForm.use_amount" placeholder="满多少元可用,0为无门槛" type="number"></el-input>
				</el-form-item>

				<el-form-item label="每人领取张数" prop="send_num">
					<el-input v-model="dataForm.send_num" type="number" min="1"></el-input>
				</el-form-item>

				<el-form-item label="优惠券有效期(天)" prop="term">
					<el-input v-model="dataForm.term" type="number" min="1" placeholder="用户自领取后多少天有效期"></el-input>
				</el-form-item>

				<el-form-item label="规则描述" prop="rules">
					<el-input v-model="dataForm.rules" type="textarea" :rows="2"></el-input>
				</el-form-item>

				<el-form-item label="按钮文字:" prop="btn_text">
					<el-input v-model="dataForm.btn_text"></el-input>
				</el-form-item>

				<el-form-item label="背景图链接" prop="activity_image">
					<el-input v-model="dataForm.activity_image" placeholder="引用外部链接,图片比例参考750x1334" type="textarea"
						:rows="2"></el-input>
				</el-form-item>

			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="submitForm()">保存</el-button>
				<el-button @click="resetForm()">重置</el-button>
			</div>
		</el-dialog>






	</div>
</template>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>

<style scoped>
	.dataList {
		height: calc(100vh - 250px);
	}

	.operation {
		/* padding-bottom: 10px; */
	}

	.page {
		padding-top: 20px;
		float: right;
	}
</style>

<script>
	export default {
		data() {
			return {
				total: 0,
				selectForm: {
					size: 20,
					page: 1,
					name: '',
				},

				dialogVisible: false,


				//列表数据
				dataList: [

				],


				//表单数据
				dataForm: {
					id: 0, //编辑时才有
					name: '',
					use_amount: 0,
					types: 1,
					amount: "",
					discount: "",
					send_num: 1,
					term: 1,
					expires: "",
					rules: "",
					btn_text: "领取",
					activity_image: "",
				},
				//表单验证规则
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					types: [{
						required: true,
						message: '请输选择类型',
						trigger: 'blur'
					}],
					expires: [{
						required: true,
						message: '请输输入',
						trigger: 'blur'
					}],
					send_num: [{
						required: true,
						message: '请输输入',
						trigger: 'blur'
					}],
					term: [{
						required: true,
						message: '请输输入',
						trigger: 'blur'
					}],
					btn_text: [{
						required: true,
						message: '请输输入',
						trigger: 'blur'
					}],
					activity_image: [{
						required: true,
						message: '请输输入',
						trigger: 'blur'
					}],

				},


			};
		},

		created() {

			this.getList();
		},

		methods: {
			/**
			 * 获取列表
			 */
			getList() {
				this.$http
					.post('/manage/activity/getActivityCouponList', this.selectForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							this.dataList = result.list;
							this.total = result.total;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			changeStatus(e, id) {
				this.$http
					.post('/manage/activity/changeActivityCouponStatus', {
						id: id,
						status: e
					})
					.then(res => {
						let {
							errcode,
							msg
						} = res;

						if (errcode != 0) {
							this.$message.error(msg);
						}
					})
					.catch(() => {
						this.$message.error('保存失败');
					});
			},





			/**
			 * 表单提交
			 */
			submitForm() {
				this.$refs['ruleForm'].validate(valid => {
					if (valid) {
						console.log(this.dataForm);

						this.$http
							.post('/manage/activity/saveActivityCoupon', this.dataForm)
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								console.log(errcode);

								if (errcode == 0) {
									this.$message({
										message: '成功',
										type: 'success'
									});
									this.resetForm();
									this.dialogVisible = false;
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			/**
			 * 表单重置
			 */
			resetForm() {
				this.dataForm = {
					id: 0, //编辑时才有
					name: '',
					use_amount: 0,
					types: 1,
					amount: "",
					discount: "",
					send_num: 1,
					term: 1,
					expires: "",
					rules: "",
					btn_text: "",
					activity_image: "",
				};
				this.$refs['ruleForm'].resetFields();
			},

			/**
			 * 删除
			 * @param {Object} row
			 */
			handleDelete(row) {


				this.$confirm('删除【' + row.name + '】, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.post('/manage/activity/delActivityCoupon', {
								id: row.id
							})
							.then(res => {
								let {
									errcode,
									msg
								} = res;

								if (errcode == 0) {
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
									this.getList();
								} else {
									this.$message.error(msg);
								}
							})
							.catch(() => {
								this.$message.error('保存失败');
							});
					})
					.catch(() => {});
			},

			/**
			 * 生成链接
			 * @param {Object} row
			 */
			handleBuildLink(row) {

				this.$http
					.post('/manage/activity/buildActivityCouponLink', {
						id: row.id
					})
					.then(res => {
						let {
							errcode,
							msg
						} = res;

						if (errcode == 0) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.getList();
						} else {
							this.$message.error(msg);
						}
					})
					.catch(() => {
						this.$message.error('失败');
					});

			},

			/**
			 * 查看详情
			 * @param {Object} e
			 */
			handleEdit(row) {
				console.log(row);
				this.dataForm = JSON.parse(JSON.stringify(row));

				console.log(this.dataForm);
				this.dialogVisible = true;
			},



			/**
			 * 分页改变
			 * @param {Object} e
			 */
			handleCurrentChange(e) {
				this.selectForm.page = e;
				this.getList();
			},


		}
	};
</script>
