<template>
	<div style="width: 60%;">
		<el-form :model="dataForm"  label-width="180px" label-position="right">
			

				
			<el-form-item label="男性体质测试题目">
				<el-input type="textarea" rows="10" v-model="dataForm.test_man_data"></el-input>
			</el-form-item>
			
			<el-form-item label="女性体质测试题目">
				<el-input type="textarea" rows="10" v-model="dataForm.test_woman_data"></el-input>
			</el-form-item>
			
			<el-form-item label="体质测试积分">
				<el-input  v-model="dataForm.test_integral" maxlength="10"></el-input>
			</el-form-item>
		
			
			<el-form-item label="运费(元)">
				<el-input  v-model="dataForm.freight" maxlength="10"></el-input>
			</el-form-item>
		
			<el-form-item label="满n单免运费">
				<el-input  v-model="dataForm.free_shipping_num" maxlength="10"></el-input>
			</el-form-item>
		
			<el-form-item label="满n元免运费">
				<el-input  v-model="dataForm.free_shipping_amount" maxlength="10"></el-input>
			</el-form-item>

			
			<el-form-item>
				<el-button type="primary" @click="submitForm()">保存</el-button>
			</el-form-item>

		</el-form>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,

				dataForm: {
					test_man_data: "",
					test_woman_data: "",
					test_integral: "",
					freight: "",
					free_shipping_num:"",
					free_shipping_amount:"",
				},



			};
		},

		created() {
			this.loadData();
		},

		methods: {

			loadData() {
				this.$http
					.post('/manage/config/info')
					.then(res => {
						console.log(res);
						let {
							errcode,
							result
						} = res;
						if (errcode === 0) {
							
							
							this.dataForm.test_man_data = result['test_man_data']['v'];
							this.dataForm.test_woman_data = result['test_woman_data']['v'];
							this.dataForm.test_integral = result['test_integral']['v'];
							this.dataForm.freight = result['freight']['v'];
							this.dataForm.free_shipping_num = result['free_shipping_num']['v'];
							this.dataForm.free_shipping_amount = result['free_shipping_amount']['v'];
							
							// this.goodsList = res'ult;
						}
					})
					.catch(error => {
						console.log(error);
					});
			},


			//确认
			submitForm() {

				this.$http
					.post('/manage/config/save', this.dataForm)
					.then(res => {
						console.log(res);
						let {
							errcode,
						} = res;
						if (errcode === 0) {
						
							this.loadData();

							this.$message({
								message: '保存成功！',
								type: 'success'
							});
						}
					})
					.catch(error => {
						console.log(error);
					});
			},

	

		}
	};
</script>

<style>
</style>
