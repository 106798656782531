<template>
	<div style="width: 60%;">
		<el-form :model="dataForm"  label-width="180px" label-position="right">
			

				
			<el-form-item label="物料 克数">
				<el-input type="textarea" rows="10" v-model="skus" placeholder="一行一种物料 多种物料换行, 名称+空格+克数 例如: 花旗参 15"></el-input>
			</el-form-item>
			
			<el-form-item label="计算结果">
				<el-input type="textarea" rows="10" v-model="priceResult"></el-input>
			</el-form-item>
			
		
		
			
			<el-form-item>
				<el-button type="primary" @click="submitForm()">计算</el-button>
			</el-form-item>

		</el-form>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,

				skus: "",
				priceResult: "",


			};
		},

		created() {

		},

		methods: {



			//确认
			submitForm() {

				this.$http
					.post('/manage/sku/pricecount', {"skus":this.skus})
					.then(res => {
						console.log(res);
						let {
							errcode,
							msg,
							result
						} = res;
						if (errcode === 0) {
						
							this.priceResult = result
						
						}else{
							this.$message.error(msg);
						}
					})
					.catch(error => {
						console.log(error);
					});
			},

	

		}
	};
</script>

<style>
</style>
