<template>
	<div class="login-page">
		<div class="login">
			<h1>登录</h1>

			<el-form label-width="80px">
				<el-form-item label="" label-width="0"><el-input v-model="formData.username" placeholder="用户名"></el-input></el-form-item>
				<el-form-item label="" label-width="0"><el-input v-model="formData.password" placeholder="密码" type="password"></el-input></el-form-item>
				<el-button class="but" @click="doLogin">登录</el-button>
			</el-form>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			formData: {
				username: '',
				password: ''
			}
		};
	},

	methods: {
		doLogin() {
			this.$http
				.post('/manage/login', this.formData)
				.then(res => {
					const { errcode, msg, result } = res;
					if (errcode === 0) {
						this.$message({
							message: '登录成功',
							type: 'success'
						});
						
						this.$cache.set('token', result.token, 86400000)
						
						setTimeout(()=>{
							window.location.reload();
						},1000);
						
					} else {
						this.$message.error(msg);
					}
				})
				.catch(error => {
					console.log(error);
					this.$message.error('登录失败');
				});
		}
	}
};
</script>

<style scoped>
.login-page {
	display: flex;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-color: #4a374a;
}

.login {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -150px 0 0 -150px;
	width: 300px;
	height: 300px;
}
.login h1 {
	color: #fff;
	text-shadow: 0 0 10px;
	letter-spacing: 1px;
	text-align: center;
}
h1 {
	font-size: 2em;
	margin: 0.67em 0;
}
el-input {
	width: 278px;
	height: 18px;
	margin-bottom: 10px;
	outline: none;
	padding: 10px;
	font-size: 13px;
	color: #fff;
	text-shadow: 1px 1px 1px;
	border-top: 1px solid #312e3d;
	border-left: 1px solid #312e3d;
	border-right: 1px solid #312e3d;
	border-bottom: 1px solid #56536a;
	border-radius: 4px;
	background-color: #2d2d3f;
}
.but {
	width: 300px;
	min-height: 20px;
	display: block;
	background-color: #4a77d4;
	border: 1px solid #3762bc;
	color: #fff;
	padding: 9px 14px;
	font-size: 15px;
	line-height: normal;
	border-radius: 5px;
	margin: 0;
}
</style>
