export default {
	// api_domain:"http://api.devgdbtshop.com",
	// upload_api:"http://api.devgdbtshop.com/manage/upload/image",
	// upload_api68:"http://api.devgdbtshop.com/manage/upload/image68",
	// upload_api200:"http://api.devgdbtshop.com/manage/upload/image200",
	// upload_sourceImage:"http://api.devgdbtshop.com/manage/upload/sourceImage",
	
	api_domain:"https://api.guangdongbaotang.com",
	upload_api:"https://api.guangdongbaotang.com/manage/upload/image",
	upload_api68:"https://api.guangdongbaotang.com/manage/upload/image68",
	upload_api200:"https://api.guangdongbaotang.com/manage/upload/image200",
	upload_sourceImage:"https://api.guangdongbaotang.com/manage/upload/sourceImage",
	
	timeout:8000
}